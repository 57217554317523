var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lead-groups"},[_c('v-card',{staticClass:"rounded-10 border-1 border-color--grey",attrs:{"elevation":"3","min-height":"550"}},[_c('v-card-title',{staticClass:"mb-2"},[_c('h2',{staticClass:"heading primary--text font-weight-bold"},[_vm._v("Lead Groups")]),_c('v-spacer'),_c('v-btn',{staticClass:"primary text-none",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Back ")])],1),_c('v-card-text',[(_vm.groups.length)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"rounded-10 mb-1 border-1 border-color--grey px-0",attrs:{"cols":_vm.mdAndUp ? 3 : 12}},[_c('v-list',{attrs:{"dense":"","subheader":"","flat":""}},[_c('v-list-item',[_c('v-list-item-content',{staticClass:"title primary--text"},[_vm._v(" Groups ")]),_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.newGroup.apply(null, arguments)}}},[_vm._v(" mdi-plus-circle-outline ")])],1)],1),_c('v-divider'),_vm._l((_vm.groups),function(group){return _c('v-list-item',{key:group.id,class:{ primary: _vm.active_group.id === group.id },on:{"click":function($event){$event.stopPropagation();_vm.active_group = group}}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-content',_vm._g(_vm._b({},'v-list-item-content',attrs,false),on),[_c('v-list-item-title',{staticClass:"subtitle-2",class:[
                        _vm.active_group.id !== group.id
                          ? 'primary--text'
                          : 'light--text'
                      ]},[_vm._v(" "+_vm._s(_vm._f("ucwords")(group.name))+" ")]),_c('v-list-item-subtitle',{class:[
                        _vm.active_group.id !== group.id
                          ? 'primary--text'
                          : 'light--text'
                      ]},[_vm._v(" Leads : "),_c('span',[_vm._v(" "+_vm._s(group.leads_count || 0)+" ")])])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(group.description || group.name))])]),_c('v-list-item-action',[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"flat":"","dense":""}},[_c('v-list-item',{staticClass:"text-right",on:{"click":function($event){$event.stopPropagation();return _vm.editGroup(group)}}},[_c('v-list-item-title',{staticClass:"primary--text"},[_vm._v(" Edit ")])],1),_c('v-list-item',{staticClass:"text-right",on:{"click":function($event){$event.stopPropagation();return _vm.deleteGroup(group)}}},[_c('v-list-item-title',{staticClass:"primary--text"},[_vm._v(" Delete ")])],1)],1)],1)],1)],1)})],2)],1),_c('v-col',{staticClass:"rounded-10 py-0 mx-sm-0 mb-1"},[(_vm.active_group)?[_c('empty',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading_items),expression:"loading_items"}],attrs:{"headline":"Loading leads... Please wait.","icon":"mdi-spin mdi-loading"}}),_c('VueTable',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading_items),expression:"!loading_items"}],key:_vm.componentKey,attrs:{"items":_vm.items,"headers":_vm.headers,"show-row-actions":true,"icon":"mdi-alpha-l-box-outline","title":_vm.active_group.name,"no-more-data":!_vm.has_more_items,"empty-text":"No leads yet","show-select":false,"loading":_vm.loading_more_items},on:{"load-more":_vm.loadMoreLeads,"delete-selected":function($event){return _vm.open_bulk_delete_dialog($event)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [(_vm.analytics)?_c('v-expansion-panels',{staticClass:"border-1 border-color--grey mb-1",attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var open = ref.open;
return [_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"md":"4","sm":"12"}},[_c('span',{staticClass:"subtitle-2 primary--text"},[_vm._v(" Group Analytics ")])]),_c('v-spacer'),(!open)?_c('v-col',{staticClass:"primary--text",attrs:{"md":"3","sm":"12"}},[_c('v-fade-transition',{attrs:{"leave-absolute":""}},[_c('span',{staticClass:"subtitle-2"},[_vm._v(" Leads : "+_vm._s(_vm.analytics.leads_count || 0)+" ")])])],1):_vm._e()],1)]}}],null,false,1465104353)}),_c('v-expansion-panel-content',[_c('v-row',{attrs:{"dense":""}},[_c('v-sheet',{staticClass:"fullwidth mt-1 pa-3",attrs:{"color":"grey lighten-3 primary--text"}},[_c('h5',{staticClass:"text-center fullwidth title"},[_vm._v("Leads")]),_c('h4',{staticClass:"subtitle-2"},[_vm._v(" Total Leads:"),_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.analytics.leads_count || 0)+" ")])]),_c('h4',{staticClass:"subtitle-2"},[_vm._v(" Leads with valid contact numbers: "),_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.analytics.valid_e164_format.count || 0)+" ")])]),_c('h4',{staticClass:"subtitle-2"},[_vm._v(" Leads with invalid contact numbers: "),_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.analytics.invalid_e164_format.count || 0)+" ")])]),_c('h4',{staticClass:"subtitle-2"},[_vm._v(" Leads with no contact numbers: "),_c('span',{staticClass:"float-right"},[_vm._v(" "+_vm._s(_vm.analytics.no_contact_numbers.count || 0)+" ")])])])],1)],1)],1)],1):_vm._e()]},proxy:true},{key:"row-slot",fn:function(ref){
                      var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("ucwords")(item.fullname)))]),_c('td',[_vm._v(_vm._s(item.email))]),_c('td',[_vm._v(_vm._s(item.contacts.e164 || item.contacts.input || ''))]),_c('td',[_vm._v(_vm._s(_vm.concatedGroups(item)))]),_c('td',[_c('ActionDropdown',{attrs:{"item":item,"has-edit":_vm.can_edit,"has-delete":_vm.can_delete,"has-view":false},on:{"delete":function($event){return _vm.deleteLead(item)},"edit":function($event){return _vm.editLead(item)}}})],1)]}}],null,false,1099371610)})]:_c('empty',{attrs:{"headline":"Select from group.","icon":"mdi-account-group"}})],2)],1):_c('empty',{attrs:{"min-height":"400","headline":"No groups yet.","icon":"mdi-account-group"}})],1)],1),_c('LeadDialog',{attrs:{"groups":_vm.groups,"item":_vm.activeLead,"dialog":_vm.open_lead_dialog},on:{"update:dialog":function($event){_vm.open_lead_dialog=$event},"save":_vm.handleSaveLead,"update":_vm.handleUpdateLead,"new-group":_vm.appendGroup}}),_c('LeadGroupDialog',{attrs:{"group":_vm.toEditGroup},on:{"save":_vm.saveGroup,"update":_vm.updateGroup},model:{value:(_vm.open_lead_group_dialog),callback:function ($$v) {_vm.open_lead_group_dialog=$$v},expression:"open_lead_group_dialog"}}),_c('DeleteDialog',{attrs:{"open-dialog":_vm.delete_dialog,"title":"Confirmation required","text-content":"Delete this lead? This cannot be undone.","delete-button-text":"Yes! Delete"},on:{"update:openDialog":function($event){_vm.delete_dialog=$event},"update:open-dialog":function($event){_vm.delete_dialog=$event},"delete":_vm.handleDeleteLead}}),_c('DeleteDialog',{attrs:{"open-dialog":_vm.open_delete_lead_group_dialog,"title":"Confirmation required","text-content":"Delete this lead group?","delete-button-text":"Yes! Delete"},on:{"update:openDialog":function($event){_vm.open_delete_lead_group_dialog=$event},"update:open-dialog":function($event){_vm.open_delete_lead_group_dialog=$event},"delete":function($event){return _vm.handleDeleteGroup(_vm.toEditGroup ? _vm.toEditGroup.id : null)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }