<template>
  <CustomDialog
    :open.sync="open"
    :title="isEdit ? `Edit Lead` : `Create New Lead`"
    @click-close="close_dialog"
    :max-width="mdAndDown ? `100%` : 900"
    ref="dialog"
    :button2-text="isEdit ? `Update` : `Save`"
    @button2="save"
    @button1="close_dialog"
    :main-btn-disabled="!valid"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text class="py-0">
          <v-form v-model="valid">
            <v-row dense>
              <v-col :cols="mdAndUp ? 6 : 12">
                <label class="primary--text subtitle-2">First Name</label>
                <v-text-field
                  v-model="mapping.first_name"
                  :disabled="form_disabled"
                  outlined
                  dense
                  class="mb-1"
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  hide-details="auto"
                  placeholder="Optional"
                ></v-text-field>
              </v-col>
              <v-col :cols="mdAndUp ? 6 : 12">
                <label class="primary--text subtitle-2">Last Name</label>
                <v-text-field
                  v-model="mapping.last_name"
                  :disabled="form_disabled"
                  outlined
                  dense
                  class="mb-1"
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  hide-details="auto"
                  placeholder="Optional"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <label class="primary--text subtitle-2">Email Address</label>
                <v-text-field
                  v-model="mapping.email"
                  :disabled="form_disabled"
                  outlined
                  dense
                  class="mb-1"
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  :rules="emailRules"
                  hide-details="auto"
                  placeholder="Required"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col :cols="mdAndUp ? 6 : 12">
                <label class="primary--text subtitle-2">Contact Number</label>
                <CustomPhoneInput
                  v-model="mapping.contact_number"
                ></CustomPhoneInput>
              </v-col>
              <v-col :cols="mdAndUp ? 6 : 12">
                <label class="primary--text subtitle-2">Location</label>
                <v-text-field
                  v-model="mapping.address"
                  :disabled="form_disabled"
                  outlined
                  class="mb-1"
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  dense
                  hide-details="auto"
                  placeholder="Optional"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col :cols="mdAndUp ? 6 : 12">
                <label class="primary--text subtitle-2">
                  Interest (Separated by comma)
                </label>
                <v-text-field
                  v-model="mapping.interests"
                  :disabled="form_disabled"
                  outlined
                  dense
                  class="mb-1"
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  hide-details="auto"
                  placeholder="Optional"
                ></v-text-field>
              </v-col>
              <v-col :cols="mdAndUp ? 6 : 12">
                <label class="primary--text subtitle-2">Status</label>
                <v-select
                  dense
                  hide-details="auto"
                  :disabled="form_disabled"
                  :items="statuses"
                  item-text="name"
                  item-value="value"
                  placeholder="Select status"
                  v-model="import_settings.status"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col :cols="mdAndUp ? 6 : 12">
                <label class="primary--text subtitle-2">Organization</label>
                <v-text-field
                  v-model="mapping.organization"
                  :disabled="form_disabled"
                  outlined
                  dense
                  class="mb-1"
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  hide-details="auto"
                  placeholder="Optional"
                ></v-text-field>
              </v-col>
              <v-col :cols="mdAndUp ? 6 : 12">
                <label class="primary--text subtitle-2">Groups</label>
                <v-select
                  dense
                  item-value="id"
                  item-text="name"
                  v-model="import_settings.groups"
                  :disabled="form_disabled"
                  :items="current_groups"
                  multiple
                  deletable-chips
                  chips
                  class="d-flex align-center"
                  small-chips
                  hide-details="auto"
                  outlined
                  placeholder="Select or create groups"
                >
                  <template v-slot:append-outer>
                    <v-menu bottom left :close-on-content-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <v-card flat>
                        <v-card-text>
                          <label class="primary--text subtitle-2">
                            Group Name
                          </label>
                          <v-text-field
                            name
                            :rules="requiredRules"
                            v-model="new_group"
                            hide-details="auto"
                            append-outer-icon="mdi-content-save-move-outline"
                            outlined
                            dense
                            color="primary"
                            @click:append-outer="saveNewGroup"
                          >
                          </v-text-field>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import _cloneDeep from 'lodash/cloneDeep'
import _map from 'lodash/map'
import request from '@/services/axios_instance'
import CustomPhoneInput from '@/common/CustomPhoneInput.vue'

export default {
  name: 'LeadDialog',
  components: { CustomPhoneInput, CustomDialog },
  props: {
    item: Object,
    dialog: { type: Boolean, default: false },
    groups: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data: () => ({
    form_disabled: false,
    lead: null,
    open: false,
    valid: false,
    current_groups: [],
    new_group: null,
    statuses: [
      { value: 'active', name: 'Active' },
      { value: 'inactive', name: 'Inactive' }
    ],
    import_settings: {
      status: 'active',
      groups: []
    },
    mapping: {
      first_name: null,
      last_name: null,
      email: null,
      contact_number: { input: null },
      organization: null,
      address: null,
      interests: null
    },
    emailRules: [
      (v) => !!v || 'Email is required',
      (v) =>
        (v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) ||
        'E-mail must be valid'
    ]
  }),
  watch: {
    dialog(val) {
      this.open = val
      if (val && !this.isEdit) this.resetData()
    },
    item: {
      handler: function (val) {
        this.mapItem(val)
      },
      immediate: true,
      deep: true
    },
    open(val) {
      this.$emit('update:dialog', val)
    },
    groups: {
      handler: function (val) {
        this.current_groups = _cloneDeep(val)
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    isEdit() {
      return !!this.item
    }
  },
  methods: {
    close_dialog() {
      this.open = false
    },
    resetData() {
      this.form_disabled = false
      this.mapping = {
        first_name: null,
        last_name: null,
        email: null,
        contact_number: { input: null },
        organization: null,
        address: null,
        interests: null
      }
      this.import_settings = {
        status: 'active',
        groups: []
      }
    },
    save() {
      let payload = Object.assign({}, this.mapping, this.import_settings)
      this.$emit(this.isEdit ? 'update' : 'save', payload)
    },
    mapItem(item) {
      if (item) {
        this.mapping = {
          first_name: item.hasOwnProperty('first_name')
            ? item.first_name
            : null,
          last_name: item.hasOwnProperty('last_name') ? item.last_name : null,
          email: item.hasOwnProperty('email') ? item.email : null,
          contact_number: item.hasOwnProperty('contacts')
            ? item.contacts
            : null,
          organization:
            item.hasOwnProperty('others') &&
            item.others.hasOwnProperty('organization')
              ? item.others.organization
              : null,
          address:
            item.hasOwnProperty('others') &&
            item.others.hasOwnProperty('address')
              ? item.others.address
              : null,
          interests: item.hasOwnProperty('interests') ? item.interests : null
        }
        this.import_settings = {
          status: item.hasOwnProperty('status') ? item.status : 'active',
          groups: item.hasOwnProperty('groups') ? _map(item.groups, 'id') : []
        }
      }
    },
    saveNewGroup() {
      if (!this.new_group || this.new_group.trim().length < 1) {
        this.appSnackbar('Group name is required!', 'error')
      } else {
        request
          .post(`api/leads/groups`, { name: this.new_group })
          .then(({ data }) => {
            this.new_group = null
            this.current_groups.unshift(data)
            this.$emit('new-group', data)
            this.appSnackbar('Group added!', 'success')
          })
      }
    }
  }
}
</script>

<style scoped></style>
