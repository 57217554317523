import request from '@/services/axios_instance'
import _map from 'lodash/map'
import _chunk from 'lodash/chunk'
import { mapGetters } from 'vuex'

export const lead_mixins = {
    data: () => ({
        groups: [],
        activeLead: null,
        open_lead_dialog: false,
        open_lead_group_dialog: false,
        open_delete_lead_group_dialog: false,
        delete_dialog: false
    }),
    computed: {
        ...mapGetters(['user']),
        can_delete() {
            return this.user.is_admin || this.user.is_manager
        },
        can_edit() {
            return this.user.is_admin || this.user.is_manager
        }
    },
    methods: {
        editLead(lead) {
            this.activeLead = lead
            this.open_lead_dialog = true
        },
        deleteLead(lead) {
            this.activeLead = lead
            this.delete_dialog = true
        },
        handleSaveLead(payload) {
            request
                .post(`api/leads`, payload)
                .then(({ data }) => {
                    this.items.unshift(data)
                    this.appSnackbar('Lead successfully created!')
                })
                .finally(() => {
                    this.activeLead = null
                    this.open_lead_dialog = false
                })
        },
        handleUpdateLead(payload) {
            request
                .put(`api/leads/${this.activeLead.id}`, payload)
                .then(({ data }) => {
                    let index = this.items.findIndex((i) => i.id === data.id)
                    if (~index) {
                        this.items.splice(index, 1, data)
                        this.activeLead = null
                        this.appSnackbar('Lead successfully updated!')
                    }
                    this.open_lead_dialog = false
                })
        },
        handleDeleteLead() {
            request.delete(`api/leads/${this.activeLead.id}`).then(({ data }) => {
                let index = this.items.findIndex((i) => i.id === this.activeLead.id)
                if (~index) {
                    this.items.splice(index, 1)
                    this.activeLead = null
                    this.appSnackbar('Lead successfully deleted!')
                }
                this.delete_dialog = false
            })
        },
        concatedGroups(item, cut) {
            if (item.hasOwnProperty('groups') && item.groups.length) {
                let chunks = _chunk(item.groups, cut)
                let others = item.groups.length - cut
                let first = _map(chunks[0], 'name').join(', ')
                if (others > 0) first = `${first} & ${others} others`
                return first
            }
            return ''
        },
        concatedLeads(item, cut) {
            if (item.hasOwnProperty('leads') && item.leads.length) {
                let chunks = _chunk(item.leads, cut)
                let others = item.leads.length - cut
                let first = _map(chunks[0], 'first_name').join(', ')
                if (others > 0) first = `${first} & ${others} others`
                return first
            }
            return ''
        },
        appendGroup(data) {
            this.groups.push(data)
        },
        saveGroup(payload) {
            request
                .post(`api/leads/groups`, payload)
                .then(({ data }) => {
                    this.appendGroup(data)
                    this.appSnackbar('New lead group added.')
                })
                .finally(() => {
                    this.open_lead_group_dialog = false
                })
        },
        updateGroup(payload) {
            request
                .put(`api/leads/groups/${payload.id}`, payload)
                .then(({ data }) => {
                    let index = this.groups.findIndex((i) => i.id === data.id)
                    if (~index) {
                        this.groups.splice(index, 1, data)
                        this.appSnackbar('Lead group updated.')
                    }
                })
                .finally(() => {
                    this.open_lead_group_dialog = false
                })
        },
        handleDeleteGroup(id) {
            if (!id) return
            request
                .delete(`api/leads/groups/${id}`)
                .then(({ data }) => {
                    let index = this.groups.findIndex((i) => i.id === id)
                    if (~index) {
                        this.groups.splice(index, 1)
                        this.appSnackbar('Lead group deleted.')
                    }
                })
                .finally(() => {
                    this.open_delete_lead_group_dialog = false
                })
        }
    }
}