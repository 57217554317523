<template>
  <CustomDialog
    :open.sync="open"
    :title="isEdit ? `Edit Lead Group` : `Create New Lead Group`"
    @click-close="close_dialog"
    :max-width="mdAndDown ? `100%` : 500"
    ref="dialog"
    :button2-text="isEdit ? `Update` : `Save`"
    @button2="save"
    @button1="close_dialog"
    :main-btn-disabled="!valid"
  >
    <template v-slot:content>
      <v-card flat>
        <v-card-text class="py-0">
          <v-form v-model="valid">
            <label class="subtitle-2 mb-1 mt-2 primary--text">
              Group Name :
            </label>
            <v-text-field
              dense
              outlined
              v-model="mapping.name"
              placeholder="Required"
              :rules="nameRules"
              clearable
              clear-icon="mdi-close-circle-outline"
            ></v-text-field>
            <label class="subtitle-2 mb-1 mt-2 primary--text">
              Group Description :
            </label>
            <v-textarea
              rows="2"
              dense
              outlined
              v-model="mapping.description"
              clearable
              placeholder="Optional"
              auto-grow
            ></v-textarea>
          </v-form>
        </v-card-text>
      </v-card>
    </template>
  </CustomDialog>
</template>

<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'

export default {
  name: 'LeadGroupDialog',
  components: { CustomDialog },
  props: {
    value: { type: Boolean, default: false },
    group: Object
  },
  data: () => ({
    open: false,
    valid: false,
    mapping: {
      name: null,
      description: null
    },
    nameRules: [
      (v) => !!v || 'This field is required',
      (v) =>
        (v && v.length >= 3) || 'This field must have atleast 3 characters',
      (v) =>
        (v && v.length <= 50) || 'This field exceeds maximum allowed characters'
    ]
  }),
  watch: {
    value(val) {
      this.open = val
    },
    open(val) {
      this.$emit('input', val)
    },
    group: {
      handler: function (val) {
        this.mapping = {
          id: val ? val.id : null,
          name: val ? val.name : null,
          description: val && val.description ? val.description : null
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    isEdit() {
      return !!this.group
    }
  },
  methods: {
    save() {
      let payload = this.mapping
      this.$emit(this.isEdit ? 'update' : 'save', payload)
    },
    close_dialog() {
      this.open = false
    }
  }
}
</script>

<style scoped></style>
