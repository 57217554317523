<template>
  <div class="lead-groups">
    <v-card
      elevation="3"
      min-height="550"
      class="rounded-10 border-1 border-color--grey"
    >
      <v-card-title class="mb-2">
        <h2 class="heading primary--text font-weight-bold">Lead Groups</h2>
        <v-spacer></v-spacer>
        <v-btn
          class="primary text-none"
          color="primary"
          @click="$router.go(-1)"
        >
          Back
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row dense v-if="groups.length">
          <v-col
            :cols="mdAndUp ? 3 : 12"
            class="rounded-10 mb-1 border-1 border-color--grey px-0"
          >
            <v-list dense subheader flat>
              <v-list-item>
                <v-list-item-content class="title primary--text">
                  Groups
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="primary" @click.stop="newGroup">
                    mdi-plus-circle-outline
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item
                v-for="group in groups"
                :key="group.id"
                :class="{ primary: active_group.id === group.id }"
                @click.stop="active_group = group"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-content v-bind="attrs" v-on="on">
                      <v-list-item-title
                        class="subtitle-2"
                        :class="[
                          active_group.id !== group.id
                            ? 'primary--text'
                            : 'light--text'
                        ]"
                      >
                        {{ group.name | ucwords }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        :class="[
                          active_group.id !== group.id
                            ? 'primary--text'
                            : 'light--text'
                        ]"
                      >
                        Leads :
                        <span>
                          {{ group.leads_count || 0 }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <span>{{ group.description || group.name }}</span>
                </v-tooltip>
                <v-list-item-action>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list flat dense>
                      <v-list-item
                        class="text-right"
                        @click.stop="editGroup(group)"
                      >
                        <v-list-item-title class="primary--text">
                          Edit
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        class="text-right"
                        @click.stop="deleteGroup(group)"
                      >
                        <v-list-item-title class="primary--text">
                          Delete
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col class="rounded-10 py-0 mx-sm-0 mb-1">
            <template v-if="active_group">
              <empty
                v-show="loading_items"
                headline="Loading leads... Please wait."
                icon="mdi-spin mdi-loading"
              ></empty>
              <VueTable
                v-show="!loading_items"
                :items="items"
                :headers="headers"
                :show-row-actions="true"
                @load-more="loadMoreLeads"
                @delete-selected="open_bulk_delete_dialog($event)"
                icon="mdi-alpha-l-box-outline"
                :title="active_group.name"
                :key="componentKey"
                :no-more-data="!has_more_items"
                empty-text="No leads yet"
                :show-select="false"
                :loading="loading_more_items"
              >
                <template v-slot:header>
                  <v-expansion-panels
                    flat
                    class="border-1 border-color--grey mb-1"
                    v-if="analytics"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <template v-slot:default="{ open }">
                          <v-row no-gutters align="center" justify="center">
                            <v-col md="4" sm="12">
                              <span class="subtitle-2 primary--text">
                                Group Analytics
                              </span>
                            </v-col>
                            <v-spacer />
                            <v-col
                              md="3"
                              sm="12"
                              v-if="!open"
                              class="primary--text"
                            >
                              <v-fade-transition leave-absolute>
                                <span class="subtitle-2">
                                  Leads : {{ analytics.leads_count || 0 }}
                                </span>
                              </v-fade-transition>
                            </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row dense>
                          <v-sheet
                            class="fullwidth mt-1 pa-3"
                            color="grey lighten-3 primary--text"
                          >
                            <h5 class="text-center fullwidth title">Leads</h5>
                            <h4 class="subtitle-2">
                              Total Leads:<span class="float-right">
                                {{ analytics.leads_count || 0 }}
                              </span>
                            </h4>
                            <h4 class="subtitle-2">
                              Leads with valid contact numbers:
                              <span class="float-right">
                                {{ analytics.valid_e164_format.count || 0 }}
                              </span>
                            </h4>
                            <h4 class="subtitle-2">
                              Leads with invalid contact numbers:
                              <span class="float-right">
                                {{ analytics.invalid_e164_format.count || 0 }}
                              </span>
                            </h4>
                            <h4 class="subtitle-2">
                              Leads with no contact numbers:
                              <span class="float-right">
                                {{ analytics.no_contact_numbers.count || 0 }}
                              </span>
                            </h4>
                          </v-sheet>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
                <template v-slot:row-slot="{ item }">
                  <td>{{ item.fullname | ucwords }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.contacts.e164 || item.contacts.input || '' }}</td>
                  <td>{{ concatedGroups(item) }}</td>
                  <td>
                    <ActionDropdown
                      :item="item"
                      :has-edit="can_edit"
                      :has-delete="can_delete"
                      :has-view="false"
                      @delete="deleteLead(item)"
                      @edit="editLead(item)"
                    ></ActionDropdown>
                  </td>
                </template>
              </VueTable>
            </template>
            <empty
              v-else
              headline="Select from group."
              icon="mdi-account-group"
            >
            </empty>
          </v-col>
        </v-row>
        <empty
          min-height="400"
          v-else
          headline="No groups yet."
          icon="mdi-account-group"
        ></empty>
      </v-card-text>
    </v-card>
    <LeadDialog
      :groups="groups"
      :item="activeLead"
      :dialog.sync="open_lead_dialog"
      @save="handleSaveLead"
      @update="handleUpdateLead"
      @new-group="appendGroup"
    ></LeadDialog>
    <LeadGroupDialog
      :group="toEditGroup"
      v-model="open_lead_group_dialog"
      @save="saveGroup"
      @update="updateGroup"
    ></LeadGroupDialog>
    <DeleteDialog
      :open-dialog.sync="delete_dialog"
      title="Confirmation required"
      text-content="Delete this lead? This cannot be undone."
      delete-button-text="Yes! Delete"
      @delete="handleDeleteLead"
    ></DeleteDialog>
    <DeleteDialog
      :open-dialog.sync="open_delete_lead_group_dialog"
      title="Confirmation required"
      text-content="Delete this lead group?"
      delete-button-text="Yes! Delete"
      @delete="handleDeleteGroup(toEditGroup ? toEditGroup.id : null)"
    ></DeleteDialog>
  </div>
</template>

<script>
import { fetch_services } from '@/services/fetch/fetch_services'
import request from '@/services/axios_instance'
import VueTable from '@/common/VueTable/VueTable.vue'
import _map from 'lodash/map'
import ActionDropdown from '@/common/VueTable/ActionDropdown.vue'
import LeadDialog from '@/modules/Leads/components/LeadDialog.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import { lead_mixins } from '@/modules/Leads/lead_mixins'
import LeadGroupDialog from '@/modules/Leads/components/LeadGroupDialog'

export default {
  name: 'LeadGroup',
  components: {
    LeadGroupDialog,
    DeleteDialog,
    LeadDialog,
    ActionDropdown,
    VueTable
  },
  mixins: [fetch_services, lead_mixins],
  data: () => ({
    groups: [],
    toEditGroup: null,
    componentKey: 0,
    paths: [
      {
        text: 'Dashboard',
        disabled: false,
        route: { name: 'default-content' }
      },
      { text: 'Lead / Groups', disabled: true, route: null }
    ],
    active_group: null,
    activeLead: null,
    analytics: null,
    headers: [
      { text: 'Name', value: 'fullname', sortable: true, align: 'left' },
      { text: 'Email', value: 'email', sortable: true, align: 'left' },
      {
        text: 'Contact',
        value: 'contact_number',
        sortable: true,
        align: 'left',
        width: 150
      },
      { text: 'Status', value: 'status', sortable: true, align: 'center' },
      {
        text: 'Action',
        value: 'action',
        sortable: false,
        align: 'center',
        width: '40px'
      }
    ]
  }),
  watch: {
    active_group: {
      handler: function (val) {
        this.loadLeads()
        this.getAnalytics()
      },
      deep: true
    }
  },
  created() {
    this.loadGroup()
  },
  mounted() {
    this.$event.$emit('path-change', this.paths)
  },
  methods: {
    loadGroup() {
      request.get(`api/leads/groups?all=true`).then(({ data }) => {
        this.groups = data
        if (data.length > 0) {
          this.active_group = data[0]
        }
      })
    },
    loadLeads() {
      this.loadItems(`api/leads?group=${this.active_group.id}`, true)
    },
    loadMoreLeads() {
      this.loadMoreItems()
    },
    editGroup(group) {
      this.toEditGroup = group
      this.open_lead_group_dialog = true
    },
    deleteGroup(group) {
      this.toEditGroup = group
      this.open_delete_lead_group_dialog = true
    },
    newGroup() {
      this.toEditGroup = null
      this.open_lead_group_dialog = true
    },
    getAnalytics() {
      request
        .get(`api/leads/groups/${this.active_group.id}/analytics`)
        .then(({ data }) => {
          this.analytics = data
        })
    }
  }
}
</script>

<style scoped lang="scss"></style>
